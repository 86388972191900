import {createRouter, createWebHistory, type RouteRecordRaw} from 'vue-router'
import * as Sentry from '@sentry/vue'
import {Excludeable, type Maybe} from './types'
import {useGlobalStore} from './stores/global'
import {useCompanyStore} from './stores/company'
import {useLoadingStore} from './stores/loading'
import DpaHeader from './components/DataPrivacyAgreement/DpaHeader.vue'
import RateboardFooter from './components/RateboardFooter.vue'
import RateboardHeader from './components/header/RateboardHeader.vue'
import {useUserStore} from './stores/user'
import {useNotificationsStore} from './stores/notifications'
import {isNullOrUndefined} from './utils/utils'
import versionService from './services/VersionService'
import {version as currentVersion} from '../package.json'
import CompanyPropertiesCookie from './utils/CompanyPropertiesCookie'
import CompanyService from './services/CompanyService'
import ExpiryCookie from './utils/ExpiryCookie'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/RateboardEA/login',
      redirect: '/',
      beforeEnter: () => {
        window.location.replace('/')
      },
    },
    {
      path: '/contract',
      components: {
        default: () => import('./views/DataPrivacyAgreement.vue'),
        Header: DpaHeader,
      },
    },
    {
      path: '/manage',
      components: {
        default: () => import('./views/ManagePage.vue'),
        Header: RateboardHeader,
        Footer: RateboardFooter,
      },
      meta: {authorities: ['PMS_ADMIN', 'VIEWER']},
    },
    {
      path: '/admin',
      children: [
        {
          path: 'hotel-administration',
          children: [
            {
              path: '',
              components: {
                default: () => import('./views/administration/HotelAdministration.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
              meta: {authorities: ['ADMIN']},
            },
            {
              path: 'edit',
              components: {
                default: () => import('./views/administration/HotelAdministrationEditor.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
              props: {
                default: (route) => ({companyId: route.query.companyId}),
              },
              meta: {authorities: ['ADMIN']},
            },
          ],
        },
        {
          path: 'data-science',
          children: [
            {
              path: '',
              components: {
                default: () => import('./views/administration/DataScienceForecast.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
              meta: {authorities: ['ADMIN']},
            },
          ],
        },
        {
          path: 'data-deletion',
          components: {
            default: () => import('./views/administration/dataDeletion/DataDeletion.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
          meta: {authorities: ['ADMIN']},
          children: [
            {
              path: '',
              component: () => import('./views/administration/dataDeletion/ReservationsDeletion.vue'),
            },
            {
              path: 'blocks',
              component: () => import('./views/administration/dataDeletion/BlocksDeletion.vue'),
            },
          ],
        },
        {
          path: 'customer-success-management',
          components: {
            default: () => import('./views/administration/CustomerSuccessManagement.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
          meta: {authorities: ['ADMIN']},
        },
        {
          path: 'room-stats',
          components: {
            default: () => import('./views/administration/RoomStats.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
          meta: {authorities: ['ADMIN']},
        },
        {
          path: 'connectivity-stats',
          components: {
            default: () => import('./views/administration/ConnectivityStats.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
          meta: {authorities: ['ADMIN']},
        },
      ],
    },
    {
      path: '/analyse',
      children: [
        {
          path: 'market-trend',
          beforeEnter: () => {
            const companyStore = useCompanyStore()
            if (!companyStore.supportsMarketTrends) {
              return false
            }
          },
          components: {
            default: () => import('./views/analyse/MarketTrend.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
        },
        {
          path: 'reputation',
          components: {
            default: () => import('./views/analyse/ReputationPage.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
        },
      ],
    },
    {
      path: '/activity-log',
      components: {
        default: () => import('./views/ActivityLog.vue'),
        Header: RateboardHeader,
        Footer: RateboardFooter,
      },
    },
    {
      path: '/change-password',
      components: {
        default: () => import('./views/ChangePassword.vue'),
      },
    },
    {
      path: '/connect',
      children: [
        {
          path: 'apaleo',
          components: {
            default: () => import('./views/connect/ConnectApaleo.vue'),
          },
        },
      ],
    },
    {
      path: '/settings',
      children: [
        {
          path: 'user',
          children: [
            {
              path: '',
              components: {
                default: () => import('./views/UserSettings.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
              meta: {
                excluded: [Excludeable.COMPANY_SETUP_CHECK],
              },
            },
            {
              path: 'security',
              components: {
                default: () => import('./views/UserSettingsSecurity.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
              meta: {
                excluded: [Excludeable.COMPANY_SETUP_CHECK],
              },
            },
          ],
        },
        {
          path: 'inventory',
          meta: {authorities: ['HOTEL_ADMIN']},
          children: [
            {
              path: 'config',
              components: {
                default: () => import('./views/settings/inventory/InventoryConfiguration.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
            },
            {
              path: 'capacity',
              components: {
                default: () => import('./views/settings/inventory/InventoryCapacity.vue'),
                Header: RateboardHeader,
                Footer: RateboardFooter,
              },
            },
          ],
        },
        {
          path: 'interfaces',
          components: {
            default: () => import('./views/InterfaceConfiguration.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
        },
        {
          path: 'segmentation',
          components: {
            default: () => import('./views/settings/Segmentation.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
        },
        {
          path: 'rate-rules',
          meta: {authorities: ['ADMIN']},
          components: {
            default: () => import('./views/settings/RateRulePage.vue'),
            Header: RateboardHeader,
            Footer: RateboardFooter,
          },
        },
      ],
    },
    legacyIframe('/', '/rateboard/legacy-ui/dashboard'),
    legacyIframe('/analyse/analyse', '/rateboard/legacy-ui/analyse'),
    legacyIframe('/analyse/budget', '/rateboard/legacy-ui/analyse/budget'),
    legacyIframe('/analyse/pickup', '/rateboard/legacy-ui/analyse/pickup'),
    legacyIframe('/settings/competitors', '/rateboard/legacy-ui/settings/competitors'),
    legacyIframe('/settings/price-levels', '/rateboard/legacy-ui/settings/calendar'),
    legacyIframe('/settings/events', '/rateboard/legacy-ui/settings/events'),
    legacyIframe('/settings/booking-status', '/rateboard/legacy-ui/settings/bookingsettings'),
    legacyIframe('/settings/budget', '/rateboard/legacy-ui/settings/budgetconfiguration'),
    legacyIframe('/settings/price-levels', '/rateboard/legacy-ui/settings/calendar'),
    legacyIframe('/settings/notifications', '/rateboard/legacy-ui/settings/notifications'),
    legacyIframe('/settings/price-rules', '/rateboard/legacy-ui/settings/pricerules'),
    legacyIframe('/settings/rate-plans', '/rateboard/legacy-ui/settings/rateplan'),
    legacyIframe('/settings/restrictions', '/rateboard/legacy-ui/settings/restrictions'),
    legacyIframe('/settings/room-pricing', '/rateboard/legacy-ui/settings/roompricing'),
    legacyIframe('/settings/user', '/rateboard/legacy-ui/settings/usersettings'),
    legacyIframe('/admin/users', '/rateboard/legacy-ui/administration/users'),
    legacyIframe('/admin/reservations', '/rateboard/legacy-ui/administration/reservationoverview'),
    legacyIframe('/admin/reports', '/rateboard/legacy-ui/administration/reportsoverview'),
    legacyIframe('/admin/outbound-logs', '/rateboard/legacy-ui/administration/outboundlogs'),
    legacyIframe('/admin/monitoring', '/rateboard/legacy-ui/administration/monitoring'),
    {
      path: '/hotel-selection',
      redirect: '/',
    },
    {
      path: '/:pathMatch(.*)*',
      children: [
        {
          path: '/:pathMatch(.*)*',
          name: 'redirection',
          components: {
            default: () => import('./views/PageNotFound.vue'),
            Header: RateboardHeader,
          },
          props: {
            default: (route) => ({
              redirection: true,
              status: route.query.status,
            }),
          },
          meta: {excluded: [Excludeable.ALL]},
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'static',
          components: {
            default: () => import('./views/PageNotFound.vue'),
          },
          props: {
            default: (route) => ({
              redirection: false,
              status: route.query.status ?? '500',
            }),
          },
          meta: {excluded: [Excludeable.ALL]},
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'staticWithHeader',
          components: {
            default: () => import('./views/PageNotFound.vue'),
            Header: RateboardHeader,
          },
          props: {
            default: (route) => ({
              redirection: false,
              status: route.query.status ?? '500',
            }),
          },
          meta: {excluded: [Excludeable.COMPANY_STORE]},
        },
      ],
    },
  ],
})

router.beforeEach(async (to) => {
  const companyStore = useCompanyStore()
  const globalStore = useGlobalStore()
  const loadingStore = useLoadingStore()
  const userStore = useUserStore()

  if (ExpiryCookie.isExpired()) {
    window.location.replace(`/login?r=${to.path}`)
  }

  if (isRequired(Excludeable.GLOBAL_STORE, to.meta.excluded)) {
    try {
      await globalStore.initialize()
    } catch (error) {
      console.error('GlobalStore initialization failed')
      console.error(error)
      return {
        name: 'static',
        force: true,
        params: {pathMatch: to.path.split('/').slice(1)},
      }
    }

    if (globalStore.passwordResetActive) {
      if (!to.fullPath.includes('/change-password')) {
        window.location.replace('/change-password')
      }
      return
    }

    if (to.name !== 'staticWithHeader' && to.name !== 'static') {
      if (globalStore.hasAuthority('PMS_ADMIN') && to.path === '/') {
        window.location.replace('/admin/reservations')
      }
    }

    if (to.meta.authorities && !globalStore.hasAuthority(...to.meta.authorities)) {
      return {
        name: 'redirection',
        query: {status: 403},
        force: true,
        params: {pathMatch: to.path.split('/').slice(1)},
      }
    }

    if (isRequired(Excludeable.USER_STORE, to.meta.excluded)) {
      try {
        await userStore.initialize()
      } catch (error) {
        console.error('UserStore initialization failed')
        console.error(error)
        return {
          name: 'static',
          force: true,
          params: {pathMatch: to.path.split('/').slice(1)},
        }
      }

      if (globalStore.needsToAcceptTerms && userStore.properties.termsAccepted === false) {
        if (!to.fullPath.includes('/rateboard/user/terms')) {
          window.location.replace('/rateboard/user/terms')
        }
        return
      }
    }

    setSentryUserContext(userStore.id, userStore.username)
  }

  if (isRequired(Excludeable.COMPANY_PROPERTIES, to.meta.excluded)) {
    if (!CompanyPropertiesCookie.isSet()) {
      const defaultCompany = !isNullOrUndefined(userStore.settings.defaultCompanyId)
        ? await CompanyService.fetchSingle(userStore.settings.defaultCompanyId).catch(() => undefined)
        : undefined

      if (!isNullOrUndefined(userStore.settings.defaultCompanyId) && defaultCompany === undefined) {
        userStore.updateUserSettings({...userStore.settings, defaultCompanyId: null})
      }

      const company = defaultCompany ?? (await CompanyService.fetchFirst().catch(() => undefined))

      if (company === undefined) {
        console.error('No company assigned to user!')
        return {
          name: 'static',
          query: {status: 'NoActiveHotel'},
          force: true,
          params: {pathMatch: to.path.split('/').slice(1)},
        }
      }

      CompanyPropertiesCookie.set(company)
    }
  }

  if (isRequired(Excludeable.COMPANY_STORE, to.meta.excluded)) {
    try {
      await companyStore.initialize()
    } catch (error) {
      console.error('CompanyStore initialization failed')
      console.error(error)
      return {
        name: 'static',
        force: true,
        params: {pathMatch: to.path.split('/').slice(1)},
      }
    }

    if (isRequired(Excludeable.GLOBAL_STORE, to.meta.excluded)) {
      if (!globalStore.hasAuthority('ADMIN', 'PMS_ADMIN')) {
        if (isRequired(Excludeable.COMPANY_SETUP_CHECK, to.meta.excluded)) {
          if (isNullOrUndefined(companyStore.mainUserId)) {
            return {
              name: 'staticWithHeader',
              query: {status: 'NoMainUser'},
              force: true,
              params: {pathMatch: to.path.split('/').slice(1)},
            }
          }

          if (!companyStore.dpaAccepted && companyStore.mainUserId === globalStore.selectedUserId) {
            if (!to.fullPath.includes('/contract')) {
              window.location.href = '/contract'
            }
            return
          } else if (!companyStore.dpaAccepted) {
            return {
              name: 'staticWithHeader',
              query: {status: 'DpaNotAccepted'},
              force: true,
              params: {pathMatch: to.path.split('/').slice(1)},
            }
          }
        }
      }
    }

    setSentryCompanyContext(companyStore.id, companyStore.name)
  }

  await useNotificationsStore()
    .initialize()
    .catch((e) => console.error(`Initializing notification store failed: ${e}`))

  if (await isVersionUpdated()) {
    console.info('New version detected')
    window.location.assign(addReloadKey(to.path, new Date().getTime()))
  }

  loadingStore.isLegacyLoading = !!to.meta.legacy
})

function addReloadKey(path: string, rid: number) {
  return path.includes('?') ? `${path}&rid__=${rid}` : `${path}?rid__=${rid}`
}

function legacyIframe(path: string, src: string): RouteRecordRaw {
  return {
    path: path,
    components: {
      default: () => import('./views/IFrameView.vue'),
      Header: RateboardHeader,
    },
    props: {
      default: {src: src},
    },
    meta: {
      legacy: true,
    },
  }
}

function isRequired(excludeable: Excludeable, excluded?: Excludeable[]) {
  return !(excluded && (excluded?.includes(Excludeable.ALL) || excluded?.includes(excludeable)))
}

async function isVersionUpdated() {
  const fetchedVersion = (await versionService.getVersion()).version
  if (fetchedVersion === undefined) {
    console.error('fetchedVersion undefined')
    return false
  }
  if (fetchedVersion !== currentVersion) {
    console.log(`current version ${currentVersion}`)
    console.log(`fetched version ${fetchedVersion}`)
    return true
  }
  return false
}

function setSentryCompanyContext(companyId: Maybe<number>, companyName: Maybe<string>) {
  Sentry.setContext('company', {
    id: companyId,
    name: companyName,
  })
  Sentry.setTag('companyId', companyId)
}

function setSentryUserContext(userId: Maybe<number>, userName: Maybe<string>) {
  Sentry.setUser({
    id: userId?.toString() ?? undefined,
    username: userName ?? undefined,
  })
  Sentry.setTag('username', userName)
}
export default router
